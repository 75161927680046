import { navigate } from "gatsby-link"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../components/Layout"
import UserContainer from "../containers/UserContainer"
import ModalPopup from "../popups/ModalPopup"

//이메일 변경 인증 페이지
const authEmails = props => {
  const { putAuthEmail } = props
  const [openModal, setOpenModal] = useState(false)
  const [text, setText] = useState("")

  const checkFn = async () => {
    if (props.location.search == "" || props.location.search == undefined) return false
    let temp = props.location.search.substring(1)
    let arr = temp.split("/")
    let result = await putAuthEmail({ authNumber: arr[0], authString: arr[1] })
    if (result) {
      setText("인증에 성공하였습니다.")
      setOpenModal(true)
      navigate("/exploring")
    } else {
      setText("인증 실패")
      setOpenModal(true)
      navigate("/exploring")
    }
  }

  useEffect(() => {
    checkFn()
  }, [])

  return (
    <Layout>
      {" "}
      <ModalPopup open={openModal} onClose={() => setOpenModal(false)} contents={text} />
    </Layout>
  )
}

export default UserContainer(authEmails)
